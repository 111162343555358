import NotFound from './components/steps/NotFound';

export default [
    {
        path: "/*",
        name: 'NotFound',
        component: NotFound,
        meta: {
            showContinueButton: false,
            showBackButton: false
        }
    }
];
