export default  {
    data: function() {
        return {
        }
    },

    methods: {
    },

    mounted() {
    },

    template: '<div>Not found</div>'
}