export const ValidateResults =
{
    data: function () {
        return {}
    },

    methods: {
    },

    mounted()
    {
    },

    render(h)
    {
        var components = [];
        var validationStatuses = {};

        for (const [index, step] of Object.entries(this.$root.steps)) {
            if (step.name != 'validate') {
                components.push(h(step.name));
                validationStatuses[step.name] = step.validationStatus;
            }
        }

        if (!this.$root.submitTried) {
            this.$root.submitTried = true
            this.mainLoading = true;

            this.$nextTick(async () => {
                await this.$root.validateAndSubmit();
                this.$forceUpdate();
            });
        }

        this.$nextTick(() => {
            for (var c of components) {
                var isValid = validationStatuses[c.componentOptions.tag];

                if (isValid) {
                    c.elm.style.display = "none";
                }
            }
        });

        return h('div', components);
    }
}
