import axios from "axios";

export default {
    install: function (Vue, options) {
        Vue.$validators = function() {
            return {
                messages: {
                    FIELD_REQUIRED: 'This field is required',
                    FIELD_CANT_BE_EMPTY: 'This field can\'t be empty',
                    POSTCODE_WRONG_FORMAT: 'Please Enter a Valid Postcode',
                    POSTCODE_INVALID: 'Make sure you have entered a valid UK postcode',
                    EMAIL_WRONG_FORMAT: 'Please enter a valid email address',
                    PHONE_INVALID: 'Please enter a valid phone number'
                },

                isNotEmpty: function (value) {
                    return !(value == null || value.replace(/ /g, '') == "");
                },

                isEmail: function (email) {
                    if (email == null || email == '') {
                        return false;
                    }

                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                },

                isPostcode: function (postcode) {
                    if (postcode == null) {
                        return false;
                    }

                    postcode = postcode.replace(/\s/g, "");
                    var regex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;

                    return regex.test(postcode);
                },

                isDate: function (day, month, year) {
                    var notChanged = (month == null && day == null && year == null);

                    if (notChanged && doValidation == false) {
                        return true;
                    }

                    return month != null && day != null && year != null;
                },

                isPhone: async function (phone, productId = null, url = null, apiEndpoint = '/api/phoneValidation.php') {
                    // todo target solution is to keep here endpoint from tracking system
                    var result = await axios.get(apiEndpoint + '?phone=' + phone + '&productId=' + productId + '&url=' + url)
                                            .catch(function(err) {
                                                return {
                                                    status: 400
                                                }
                                            });

                    return result.status == 200;
                }
            }
        }
    }
}
